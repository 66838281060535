/*
 * 업무구분   : 활동성과관리
 * 화면 명    : MSPAP352P
 * 화면 설명  : 활동입력
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="활동입력" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="popupClose">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '활동입력' : '활동입력'}}</div>
              <div slot="action" class="icon-wrapper" @click="popupClose">
                <mo-icon icon="close" class="fs26rem svg-closeTy1">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <div class="mod-inp-wrap pb80">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <div class="info-title-wrap">
                  <span class="contain-title must">활동일자</span>
                </div>
                <ur-box-container class="ns-add-box">
                  <mo-date-picker bottom v-model="vActDate" placeholder="활동일자를 선택해주세요." class="ns-date-picker flex-auto" />
                </ur-box-container>  
              </ur-box-container>
              
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <div class="info-title-wrap">
                  <span class="contain-title must">방문지사</span>
                </div>
                <ur-box-container alignV="start" componentid="" direction="column">
                  <mo-text-field class="form-input-name full" v-model="vBrncInfo" @click="fn_OpenMSPBC412P()" @click-icon="fn_OpenMSPBC412P()" searchable underline placeholder="지사를 검색해주세요." />
                </ur-box-container>
              </ur-box-container>
              
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <div class="info-title-wrap">
                  <span class="contain-title must">교육인원</span>
                </div>
                <ur-box-container alignV="start" componentid="" direction="column">
                  <msp-bottom-select class="ns-dropdown-sheet" v-model="vEduNprsn" :items="vEduNprsnList" underline closeBtn />
                </ur-box-container>
              </ur-box-container>
              
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
                <div class="info-title-wrap">
                  <span class="contain-title">활동내용</span>
                </div>
                <div class="content-area mt10">
                  <mo-text-area class="full" rows="7" v-model="vActCntnt" placeholder="활동내용을 입력해주세요." />
                </div>
              </ur-box-container>
            </div>
            <template>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="popupClose">취소</mo-button>
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_confirmYes">등록</mo-button>
                </div>
              </ur-box-container>
            </template>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import piCommonUtil from '@/ui/pi/common/piCommonUtil'
  import MSPBC412P from '@/ui/bc/MSPBC412P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     ***********************************************************************************/
    name: "MSPAP352P",
    screenId: "MSPAP352P",
    components: {
      "MSPBC412P" : MSPBC412P //지사검색 팝업
    },
    /***********************************************************************************
     * 화면 UI Property 정의
     * - 파리미터 받는 변수 props 정의
     ***********************************************************************************/
    props: {
      // 등록구분
      pSchdlScCd: {
        type: String,
        default: ''
      },
      // 선택일자
      pSlctDate: {
        type: String,
        default: ''
      },
      // 선택일정
      pSchdlInfo: {
        type: Object,
        defalt: () => {}
      }
    },
    /***********************************************************************************
     * 팝업일때 팝업 활성 형태 속성 
     * - 풀 팝업 형태 일때 mode 값 설정
     ***********************************************************************************/
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()

      // 수정
      if(this.pSchdlScCd === 'U') {
        this.vActId = this.pSchdlInfo.actId // 활동ID
        this.vActDate = moment(this.pSchdlInfo.actYmd).format('YYYY-MM-DD') // 활동일자
        this.vBrncId = this.pSchdlInfo.orgNo // 지사번호
        this.vBrncNm = this.pSchdlInfo.gssOrgNm // 지사명
        this.vBrncInfo = "(" + this.vBrncId + ") " + this.$bizUtil.omitStr(this.vBrncNm, 17) // 지사정보
        this.vEduNprsn = this.pSchdlInfo.eduNprsnFgr // 교육인원
        this.vActCntnt = this.pSchdlInfo.actCntnt // 활동내용
      }
      // 등록
      else {
        this.vActDate = moment(this.pSlctDate).format('YYYY-MM-DD') // 활동일자
      }

    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /***********************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        vUserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
        vActId: '', // 활동ID
        vActDate: '', // 활동일자
        vBrncId: '', // 지사번호
        vBrncNm: '', // 지사명
        vBrncInfo: '', // 지사정보
        vEduNprsn: '1', // 교육인원
        vActCntnt: '' // 활동내용
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     ***********************************************************************************/
    computed: {
      vEduNprsnList() {
        let rtn = [];
        // 교육인원 목록 채우기
        for(let i=1; i<=999; i++) {
          rtn.push({value: String(i), text: String(i)});
        }
        return rtn;
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
       * Function명: popupClose
       * 설명: 팝업닫기
       *******************************************************************************/
      popupClose () {
        this.$emit('onPopupClose')
      },
      /******************************************************************************
      * Function명 : fn_OpenMSPBC412P
      * 설명       : 지사검색 팝업 호출
      ******************************************************************************/
      fn_OpenMSPBC412P () {
        let lv_Vm = this
        this.popup413 = lv_Vm.$bottomModal.open(MSPBC412P, {
          properties: {
          },
          listeners: {
            // 확인
            onPopupConfirm: (pData) => {
              lv_Vm.vBrncId = pData.orgNo // 지사번호
              lv_Vm.vBrncNm = pData.gssOrgNm // 지사명
              lv_Vm.vBrncInfo = "(" + pData.orgNo + ") " + this.$bizUtil.omitStr(pData.gssOrgNm, 17)

              lv_Vm.$bottomModal.close(lv_Vm.popup413)
            },
            // 닫기
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup413)
            }
          }
        },
        {
          properties : {noHeader : true}
        })
      },
      /******************************************************************************
      * Function명 : fn_confirmValidCheck
      * 설명       : 필수값 체크
      ******************************************************************************/
      fn_confirmValidCheck () {
        let isValid = true

        // 활동일자 체크
        if (piCommonUtil.isEmpty(this.vActDate)) {
          this.getStore('confirm').dispatch('ADD', '활동일자를 선택해주세요.')
          isValid = false
        }
        // 방문지사 체크
        else if (piCommonUtil.isEmpty(this.vBrncInfo)) {
          this.getStore('confirm').dispatch('ADD', '방문지사를 선택해주세요.')
          isValid = false
        }
        // 교육인원 체크
        else if (piCommonUtil.isEmpty(this.vEduNprsn)) {
          this.getStore('confirm').dispatch('ADD', '교육인원을 선택해주세요.')
          isValid = false
        }

        return isValid
      },
      /******************************************************************************
      * Function명 : fn_confirmYes
      * 설명       : 활동입력
      ******************************************************************************/
      fn_confirmYes () {
        // 필수값 체크
        if(!this.fn_confirmValidCheck()) return
        
        let lv_Vm = this
        let pParams = {}
        let cmActMngList = []
        let cmActMngInfo = {}
        
        cmActMngInfo.actId = lv_Vm.vActId // 활동ID
        cmActMngInfo.orgNo = lv_Vm.vBrncId // 방문지사번호
        cmActMngInfo.gssOrgNm = lv_Vm.vBrncNm // 방문지사명
        cmActMngInfo.eplyNo = lv_Vm.vUserInfo.userId // 사원번호 : 사용자ID(GA매니저)
        cmActMngInfo.gssEplyNm = lv_Vm.vUserInfo.userNm // 사원이름 :사용자명
        cmActMngInfo.eduNprsnFgr = lv_Vm.vEduNprsn // 교육인원
        cmActMngInfo.actYmd = lv_Vm.vActDate.replaceAll('-', '') // 활동일자
        cmActMngInfo.actCntnt = lv_Vm.vActCntnt // 활동내용
        cmActMngList.push(cmActMngInfo)

        pParams.cmActMngList = cmActMngList

        // 수정은 삭제 후 등록
        if(lv_Vm.pSchdlScCd === 'U') {
          lv_Vm.fn_delActMng(pParams);
        }else {
          lv_Vm.fn_insActMng(pParams);
        }
        
      },
      /******************************************************************************
      * Function명 : fn_insActMng
      * 설명       : 활동등록
      ******************************************************************************/
      fn_insActMng (pParams) {
        let lv_Vm = this
        const trnstId = 'txTSSAP09I1'
        const auth = 'I'
        pParams.fnScCd = '02', // 기능구분(02:매니저활동관리 등록)

        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.msgComm.msgCd === '"EFWS026"') {
            lv_Vm.$addSnackbar('저장되었습니다.')
            lv_Vm.$emit('onPopupConfirm')
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '등록시 오류가 발생하였습니다.')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
        
      },
      /******************************************************************************
      * Function명 : fn_delActMng
      * 설명       : 활동삭제
      ******************************************************************************/
      fn_delActMng (pParams) {
        let lv_Vm = this
        const trnstId = 'txTSSAP09D1'
        const auth = 'D'
        pParams.fnScCd = '04', // 기능구분(04:매니저활동관리 삭제)

        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.msgComm.msgCd === '"ECOU004"') {
            lv_Vm.fn_insActMng(pParams);
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '삭제시 오류가 발생하였습니다.')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
        
      }

    }
  };
</script>
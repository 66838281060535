/*
 * 업무구분   : 활동성과관리
 * 화면 명    : MSPAP350M
 * 화면 설명  : 매니저 활동관리
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="매니저 활동관리" :topButton="false" @on-header-left-click="fn_goMain">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      
      <!-- 일정 캘린더 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-schedule msp-schedule">
        <mo-scheduler ref="loginScheduler" :events="vSchdlList" displayPeriodUom="month" :displayPeriodCount="1" :showEmptyDay="true"
          :shrink="shrink" @period-changed="fn_PeriodChanged" @click-date="fn_OpenMSPAP351P"  
          :holidays="vHoldyList" eventTop="31px" eventContentHeight="20px"
          :msg-today="vTodayFrmt" > 
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon icon="msp-calendar"></mo-icon>
            </div>
          </template>
        </mo-scheduler>

      </ur-box-container>

      <!-- 하단 플로팅 버튼 -->
      <div class="floating-wrap ns-floating-wrap" @click="fn_OpenMSPAP352P('I')">
        <mo-floating-button color="red" class="ico-define"><mo-icon icon="msp-add"/></mo-floating-button>
      </div>
    </ur-box-container>


    <mo-snackbar ref="stackableSnackbar" stackable :duration="3000" ></mo-snackbar>

  </ur-page-container>  
</template>
<script>
  /*******************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   *******************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  import MSPAP351P from '@/ui/ap/MSPAP351P'
  import MSPAP352P from '@/ui/ap/MSPAP352P'
  export default {
    /*******************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     *******************************************************************************/
    name: 'MSPAP350M',
    screenId: 'MSPAP350M',
    components: {
      "MSPAP351P" : MSPAP351P, //일정상세 팝업
      "MSPAP352P" : MSPAP352P //활동입력 팝업
    },
    /*******************************************************************************
     * 화면 UI Property 정의
     * - 파리미터 받는 변수 props 정의
     *******************************************************************************/
    props: {
    },
    /*******************************************************************************
     * 팝업일때 팝업 활성 형태 속성 
     * - 풀 팝업 형태 일때 mode 값 설정
     *******************************************************************************/
    /*
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    */
    /*******************************************************************************
     * Life Cycle 함수 정의 영역
     * - 각단계별 예약 함수에 맞게 스크립트 작성
     *******************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      //GA매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

      // 설계사는 접근불가
      if(this.vGssAuthYN != 'Y') {
        this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: 'GA매니저 전용화면입니다.',
          title_pos_btn: '확인'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
          },
          onPopupClose: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
            }
          }
        })
      }

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      
    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /*******************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     *******************************************************************************/
    data() {
      return {
        shrink: false, // 캘린더 수축여부
        isFirst: true, // 중복 호출 방지용
        vUserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
        vGssAuthYN: '', //GA매니저 권한
        vToday: this.$bizUtil.getDateToString(new Date(), '-'), // 현재일자
        vTodayFrmt: `${moment(new Date()).format('D')}일`, // 캘린더에 노출될 현재일자
        vSlctDate: '', // 선택일자
        vSlctYm: '', // 선택년월
        vSlctDayFrmt: '', // 일정상세 팝업에 노출될 일자
        vSlctDayDwk: '', // 일정상세 팝업에 노출될 요일
        vSlctHoldyNm: '', // 일정상세 팝업에 노출될 휴일명
        vDStrYmd: '', // 캘린더의 첫날
        vDEndYmd: '', // 캘린더의 마지막날
        vSchdlList: [], // 캘린더에 노출될 일정 목록
        vHoldyList: [], // 휴일 목록
        vSchdlDtlList: [], // 일정상세 목록
        vSlctSchdlList: [], // 선택일자의 일정 목록
      
      };
    },
    /*******************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     *******************************************************************************/
    computed: {
      //변수가 변경되면 호출되는 Getter
      
    },
    /*******************************************************************************
     * watch 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
     *   (변수에 대한 값 변경시 반응형 콜백 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *       userNm : function() { this.message + ' 님 안녕하세요!'}
     *******************************************************************************/
    watch: {
      //변수가 변경되면 호출되는 함수
      
    },
    /*******************************************************************************
     * 사용자 함수 정의 영역
     *******************************************************************************/
    methods: {
      /*******************************************************************************
       * Function명: fn_goMain
       * 설명: 메인으로 이동
       *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      /*******************************************************************************
       * Function명 : fn_PeriodChanged
       * 설명       : 기간이 변경되었을 때 처리하는 Function
       *            : 기간 관련 변수
       *             displayFirstDate - 달력의 첫날
       *             displayLastDate - 달력의 마지막날
       *             periodStart - 해당 월의 첫날
       *******************************************************************************/
      fn_PeriodChanged (date) {
        // 캘린더 변경시 중복 호출 방지
        if(this.isFirst) {
          this.isFirst = false

        }else {
          this.isFirst = true
          this.vSlctDate = moment(this.vToday).format('YYYY-MM-DD')
          this.vSlctYm = moment(date.periodStart).format('YYYYMM')
          this.vDStrYmd = moment(date.displayFirstDate).format('YYYYMMDD')
          this.vDEndYmd = moment(date.displayLastDate).format('YYYYMMDD')

          this.fn_SelListHoldy() // 휴일목록 조회
        }
      },
      /*********************************************************
       * Function명: fn_SelListHoldy
       * 설명: 휴일 목록을 조회한다.
       *********************************************************/
      fn_SelListHoldy () {
        let lv_Vm = this
        let trnstId = 'txTSSAP03S2'
        let pParam = {
          inqrStrYmd: lv_Vm.vDStrYmd,
          inqrEndYmd: lv_Vm.vDEndYmd
        }
        // 휴일 및 일정 목록 초기화
        lv_Vm.vHoldyList = []
        lv_Vm.vSchdlList = []
        lv_Vm.vSchdlDtlList = []
        
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
          .then(function (response) {
            if (response.body != null) {
              lv_Vm.iCYmdMngVO = response.body.iCYmdMngVO

              if ( lv_Vm.iCYmdMngVO != null) {
                for (let j = 0; j < lv_Vm.iCYmdMngVO.length; j++) {
                  
                  // 법정공휴일 : 1, 윤년/음력 : 2
                  if (lv_Vm.iCYmdMngVO[j].holyCd === '1') {
                    let holdyYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
                    let holdyCd = lv_Vm.iCYmdMngVO[j].holyCd
                    let holdyNm = lv_Vm.iCYmdMngVO[j].holyNm
                    lv_Vm.vHoldyList.push({date: holdyYmd, label: '', holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: holdyCd, holyNm: holdyNm})
                    
                    // 캘린더에 휴일을 일정처럼 표시하기 위해 일정 목록에 추가
                    let holdyObj = {}
                    holdyObj.startDate = holdyYmd
                    holdyObj.endDate = holdyYmd
                    holdyObj.title = holdyNm
                    holdyObj.classes = ['holiday', 'holiday-event']
                    lv_Vm.vSchdlList.push(holdyObj)
                  }
                }
              }
            }
          }).catch(function (error) {
            window.vue.error(error)
            console.log('@@@@@@@@@@@ 휴일 조회 err >>>', err)
          }).finally(function () {
            // 일정목록 조회
            lv_Vm.fn_SelListMngrSchdl()
          })
      },
      /*********************************************************
       * Function명: fn_SelListMngrSchdl
       * 설명: 일정 목록을 조회한다.
       *********************************************************/
      fn_SelListMngrSchdl () {
        let lv_Vm = this
        var trnstId = 'txTSSAP09S1' // 일정조회 : txTSSAP20S5, 매니저일정보회 : txTSSAP09S1
        var pParam = {}
        let cmActMngInfo = {}
        let cmActMngList = []
        
        cmActMngInfo.eplyNo = lv_Vm.vUserInfo.userId // 매니저사번
        cmActMngInfo.actYm = lv_Vm.vSlctYm // 요청년월
        cmActMngInfo.nextKeyId = 0
        cmActMngInfo.readCount = 100
        cmActMngList.push(cmActMngInfo)

        pParam.cmActMngList = cmActMngList
        pParam.cmActMngInfo = cmActMngInfo
        pParam.fnScCd = '03', // 기능구분(03:매니저활동관리 조회)

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (response.msgComm.msgCd === 'EFWS004') {
            // 실패시
            window.fdpbridge.exec('ToastPlugin', {message: response.msgComm.msgDesc}, () => {}, () => {})
          }
          if ( response.body != null) {
            lv_Vm.cmActMngList = response.body.cmActMngList

            if ( lv_Vm.cmActMngList.length > 0) {
              //캘린더 일정 건수 셋팅
              let preYmd = ''
              lv_Vm.cmActMngList.forEach((item) => {
                if(preYmd != item.actYmd) {
                  preYmd = item.actYmd
                  let result = lv_Vm.cmActMngList.filter(e => e.actYmd == preYmd)

                  if(result.length > 0) {
                    let schdlObj = {} // 일정(캘린더용)
                    schdlObj.startDate = lv_Vm.$commonUtil.dateDashFormat(preYmd) // 활동일자
                    schdlObj.endDate = lv_Vm.$commonUtil.dateDashFormat(preYmd) // 활동일자
                    schdlObj.title = result.length + '건'
                    schdlObj.classes = ['activity-event']

                    lv_Vm.vSchdlList.push(schdlObj) // 일정 목록 셋팅
                  }
                }
              })

              // 일정상세 셋팅
              lv_Vm.cmActMngList.forEach((item) => {
                let schdlDtlObj = {} // 일정상세
                schdlDtlObj = item
                schdlDtlObj.startDate = lv_Vm.$commonUtil.dateDashFormat(item.actYmd)
                schdlDtlObj.endDate = lv_Vm.$commonUtil.dateDashFormat(item.actYmd)
                schdlDtlObj.classes = 'activity'

                lv_Vm.vSchdlDtlList.push(schdlDtlObj) // 일정상세 목록 셋팅
              })
              
            }
          }
          
        }).catch(function (err) {
          console.log('@@@@@@@@@@@ 일정 조회 err >>>', err)
        })
      },
      /*******************************************************************************
       * Function명: fn_OpenMSPAP352P
       * 설명: 일정 추가 / 수정
       *******************************************************************************/
      fn_OpenMSPAP352P(schdlScCd, schdlInfo) {
        this.popup352P = this.$bottomModal.open(MSPAP352P, {
          properties: {
            pSchdlScCd: schdlScCd, // 등록구분
            pSlctDate: this.vSlctDate, // 선택일자
            pSchdlInfo: schdlInfo // 선택일정
          },
          listeners: {
            // 등록
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popup352P);
              this.fn_SelListHoldy() // 휴일목록 조회
            },
            // 취소
            onPopupClose: () => {
              this.$bottomModal.close(this.popup352P);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      /*******************************************************************************
       * Function명: fn_OpenMSPAP351P
       * 설명: 일정 상세
       *******************************************************************************/
      fn_OpenMSPAP351P (date) {
        this.vSlctDate = moment(date).format('YYYY-MM-DD')

        // 일정상세 팝업의 일자
        this.vSlctDayFrmt = `${moment(date).format('M')}월 ${moment(date).format('D')}일`

        // 일정상세 팝업의 요일
        switch (date.toString().substring(0, 3)) {
          case 'Mon':
            this.vSlctDayDwk = '월'
            break
          case 'Tue':
            this.vSlctDayDwk = '화'
            break
          case 'Wed':
            this.vSlctDayDwk = '수'
            break
          case 'Thu':
            this.vSlctDayDwk = '목'
            break
          case 'Fri':
            this.vSlctDayDwk = '금'
            break
          case 'Sat':
            this.vSlctDayDwk = '토'
            break
          case 'Sun':
            this.vSlctDayDwk = '일'
            break
        }

        
        this.vSlctSchdlList = [] // 선택일자 일정 목록 초기화
        let t_slctDay = Number(moment(date).format('D'))

        // 선택일자의 휴일명 셋팅
        this.vSlctHoldyNm = '' // 초기화
        for (let i=0; i < this.vHoldyList.length; i++) {
          let t_day = Number(moment(this.vHoldyList[i].date).format('D'))
          if (this.vHoldyList[i].holyCd === '1' && t_day === t_slctDay) {
            this.vSlctHoldyNm = this.vHoldyList[i].holyNm
          }
        }

        // 선택일자의 일정을 선택일자 일정 목록에 셋팅
        for (let i = 0; i < this.vSchdlDtlList.length; i++) {
          let t_start = Number(moment(this.vSchdlDtlList[i].startDate).format('D'))

          if (Number(moment(this.vSchdlDtlList[i].startDate).format('M')) === Number(moment(date).format('M'))) {
            if (t_start === t_slctDay) {
              this.vSlctSchdlList.push(this.vSchdlDtlList[i])
            }      
          }
        }
        
        // 선택된 일자에 일정이 있으면 일정상세 팝업 오픈
        if(this.vSlctSchdlList.length > 0) {
          
          this.popup351P = this.$bottomModal.open(MSPAP351P, {
            properties: {
              pSlctDate: this.vSlctDate,
              pSlctDayFrmt: this.vSlctDayFrmt,
              pSlctDayDwk: this.vSlctDayDwk,
              pSlctHoldyNm: this.vSlctHoldyNm,
              pSlctSchdlList: this.vSlctSchdlList
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.popup351P);
                this.fn_SelListHoldy() // 휴일목록 조회
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.popup351P)
              }
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
        }
        
      }

    }
  };
</script>
<style scoped>
</style>
/*
 * 업무구분   : 활동성과관리
 * 화면 명    : MSPAP351P
 * 화면 설명  : 상세일정
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="false" title="상세일정">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <div class="ns-bottom-sheet">
          <!-- <mo-button @click="open">OPEN</mo-button> -->
          <!-- Popup: 상세일정 -->
          <mo-bottom-sheet ref="nsbottomsheet" @closed="popupClose" class="ns-bottom-sheet closebtn">
            <!-- title 영역 -->
            <template v-slot:title>
              <span>{{ vSlctDayFrmt }}</span>
              <span class="fs14rem fwn ml10">{{ vSlctDayDwk }}</span>
              <span v-if="vSlctHoldyNm" class="badge-type holiday fs12 fwn ml10" >{{ vSlctHoldyNm }}</span>
            </template>
            <div class="ns-btn-close" @click="popupClose"></div> 

            <!-- content 영역 -->
            <div class="content-area minHauto">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list schedule-item">
                <mo-list :list-data="vSlctSchdlList">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <div class="list-item__contents" :class="{'share' : item.classes === 'share', 'activity' : item.classes === 'activity', 'person' : item.classes === 'person', 'holiday' : item.data === 'holiday' }">
                        <div class="list-item__contents__title fexTy3-1 mb0">
                          <span class="flex-1">
                            <span class="name fs19rem mr10">{{item.gssOrgNm}}</span>
                          </span>
                          <span>
                            <mo-button class="ns-btn-round h28 blue-line mr10" @click="fn_OpenMSPAP352P('U', item)">수정</mo-button >
                            <mo-button class="ns-btn-round h28 blue-line" @click="fn_alertOpen(item)">삭제</mo-button>
                          </span>
                        </div>
                        <div class="list-item__contents__info">
                          <div><span class="crTy-bk1" >{{item.actCntnt}}</span></div>
                          <div><span class="text-view fs14rem mt10" >교육인원<strong class="ml10 fwn crTy-bk1 fs16">{{item.eduNprsnFgr}}명</strong></span></div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </div>

            <!-- button 영역 -->
            <template v-slot:action>
              <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
                <div class="relative-div">
                  <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue " @click="fn_OpenMSPAP352P('I')">일정추가</mo-button>
                </div>
              </ur-box-container>
            </template>

          </mo-bottom-sheet>
          <!-- // Popup: 상세일정 -->
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP352P from '@/ui/ap/MSPAP352P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     ***********************************************************************************/
    name: "MSPAP352P",
    screenId: "MSPAP352P",
    components: {
      "MSPAP352P" : MSPAP352P //활동입력 팝업
    },
    /***********************************************************************************
     * 화면 UI Property 정의
     * - 파리미터 받는 변수 props 정의
     ***********************************************************************************/
    props: {
      // 선택일자
      pSlctDate: {
        type: String,
        default: ''
      },
      // 선택일자포멧
      pSlctDayFrmt: {
        type: String,
        default: ''
      },
      // 선택요일
      pSlctDayDwk: {
        type: String,
        default: ''
      },
      // 선택일자휴일명
      pSlctHoldyNm: {
        type: String,
        default: ''
      },
      // 일정목록
      pSlctSchdlList: {
        type: Array,
        default: []
      }
    },
    /***********************************************************************************
     * 팝업일때 팝업 활성 형태 속성 
     * - 풀 팝업 형태 일때 mode 값 설정
     ***********************************************************************************/
    //modalProps: {
    //  full: false // 하->상
    //},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역
     * - 각단계별 예약 함수에 맞게 스크립트 작성
     ***********************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      // 파라미터 셋팅
      this.vSlctDate = this.pSlctDate // 선택일자
      this.vSlctDayFrmt = this.pSlctDayFrmt // 선택일자 포멧
      this.vSlctDayDwk = this.pSlctDayDwk // 선택일자 요일
      this.vSlctHoldyNm = this.pSlctHoldyNm // 선택일자 휴일명
      this.vSlctSchdlList = this.pSlctSchdlList // 선택일자 일정목록

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      // 애니메이션을 위한 타임아웃
      setTimeout(() => {this.$refs.nsbottomsheet.open()}, 100)
    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /***********************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     ***********************************************************************************/
    data() {
      return {
        vSlctDate: '', // 선택일자
        vSlctDayFrmt: '', // 선택일자 포멧
        vSlctDayDwk: '', // 선택일자 요일
        vSlctHoldyNm: '', // 선택일자 휴일명
        vSlctSchdlList: [] // 선택일자 일정목록
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     ***********************************************************************************/
    computed: {

    },
    /***********************************************************************************
     * watch 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
     *   (변수에 대한 값 변경시 반응형 콜백 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *       userNm : function() { this.message + ' 님 안녕하세요!'}
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
       * Function명: popupClose
       * 설명: 팝업닫기
       *******************************************************************************/
      popupClose () {
        this.$emit('onPopupClose')
      },
      /*********************************************************
       * Function명: fn_alertOpen
       * 설명: 삭제버튼 클릭시 알럿 오픈
       *********************************************************/
      fn_alertOpen(pActInfo) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: '일정을 삭제하시겠습니까?',
          title_neg_btn:'취소',
          title_pos_btn: '삭제'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_delActMng(pActInfo)
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
      /*******************************************************************************
       * Function명: fn_OpenMSPAP352P
       * 설명: 일정 추가 / 수정
       *******************************************************************************/
      fn_OpenMSPAP352P(schdlScCd, schdlInfo) {
        this.popup352P = this.$bottomModal.open(MSPAP352P, {
          properties: {
            pSchdlScCd: schdlScCd, // 등록구분
            pSlctDate: this.vSlctDate, // 선택일자
            pSchdlInfo: schdlInfo // 선택일정
          },
          listeners: {
            // 등록
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popup352P);
              this.$emit('onPopupConfirm')
            },
            // 취소
            onPopupClose: () => {
              this.$bottomModal.close(this.popup352P);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_delActMng
      * 설명       : 활동삭제
      ******************************************************************************/
      fn_delActMng (pActInfo) {
        let lv_Vm = this
        const trnstId = 'txTSSAP09D1'
        const auth = 'D'
        let pParams = {}
        let cmActMngList = []
        let cmActMngInfo = {}
        
        cmActMngInfo.actId = pActInfo.actId // 활동ID
        cmActMngInfo.eplyNo = pActInfo.eplyNo // 사원번호 : 사용자ID(GA매니저)
        cmActMngList.push(cmActMngInfo)

        pParams.cmActMngList = cmActMngList
        pParams.fnScCd = '04', // 기능구분(04:매니저활동관리 삭제)

        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.msgComm.msgCd === '"ECOU004"') {
            lv_Vm.$addSnackbar('삭제되었습니다.')
            lv_Vm.$emit('onPopupConfirm')
          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '삭제시 오류가 발생하였습니다.')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
        
      }

      
    }
  };
</script>
<style scoped>
</style>
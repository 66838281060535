/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC412P
 * 화면 설명  : 지사검색
<template>
  <ur-page-container class="msp" :show-title="false" title="지사검색" :topButton="true"> <!-- @on-header-left-click="popupClose" :action-type="actionSlot" -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="popupClose()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '지사검색' : '지사검색'}}</div>
              <div slot="action" class="icon-wrapper" @click="popupClose()">
                <mo-icon icon="close" class="fs26rem svg-closeTy1">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="mt30">          
              <div class="ns-certify-sed" :class="[{'error' : isError === true}]"><!-- error 시 error class 추가 -->
                <mo-text-field ref="orgNoInput"  v-model="vSrchVal" @keyup.enter="fn_selListOrgNo" @click-icon="fn_selListOrgNo" searchable underline clearable placeholder="지사명 또는 지사코드를 입력해주세요." class="full"/>
                <div class="ns-certify-sed-txt">
                  <div class="txt-error">검색어는 필수입력항목입니다.</div>
                </div>
              </div>
            </ur-box-container>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum mt10" v-if="isSearchYn">
              <mo-list-item>
                <div class="list-item__contents__title">
                  <span class="sum">총 <strong class="crTy-blue3">{{ orgInfoList.length }}</strong> 건</span>
                </div>
              </mo-list-item>
            </ur-box-container>

            <ur-box-container v-if="orgInfoList.length > 0" alignV="start"  direction="column">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 types2">
                  <mo-list :list-data="orgInfoList">
                    <template #list-item="{item, index}">
                      <mo-list-item :class="{'on' : index == slctIdx}">
                        <mo-radio v-model="radioValue"  :value="String(index)" @input="fn_CheckOrgNo(item, index)"></mo-radio>
                        <div class="list-item__contents" @click="fn_CheckOrgNo(item, index)">
                          <div class="list-item__contents__title">
                            <span class="txtSkip num">{{item.orgNo}}</span>
                          </div>
                          <div class="list-item__contents__info">
                            <span class="branch">{{item.gssOrgNm}}</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>   
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>

            <template v-else>
              <ur-box-container v-if="!isSearchYn" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info hauto pt20 pb20">
                      <span class="ns-ftcr-gray"><p class="mt10">지사명 또는 지사코드로<br/>검색해주세요.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              
              <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info hauto pt20 pb20">
                      <span class="ns-ftcr-gray"><p class="mt10">검색된 지사가 없습니다.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
            </template>

            <ur-box-container v-if="isSelectYn" alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SelectOrgNo">선택</mo-button>
              </div>
            </ur-box-container>
          </template>
          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역
   * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
   * - screenId : 화면ID
   * - components : UI TAG 컴포넌트 정의
   ***********************************************************************************/
  name: 'MSPBC412P',
  screenId: 'MSPBC412P',
  components: {},
  /***********************************************************************************
   * 화면 UI Property 정의
   * - 파리미터 받는 변수 props 정의
   ***********************************************************************************/
  props: {
  },
  /***********************************************************************************
   * 팝업일때 팝업 활성 형태 속성 
   * - 풀 팝업 형태 일때 mode 값 설정
   ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   * - UI 바인딩 및 스크립트 화면 전역변수 정의
   ***********************************************************************************/
  data() {
    return {
      lv_HeaderviewScrollCompID: '',
      lv_UserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
      vSrchVal: '', // 검색어
      isSearchYn: false, // 조회여부
      isSelectYn: false, // 선택여부
      orgInfoList: [],
      slctIdx: 0,
      radioValue: '',
      orgInfo: {},
      isError: false,
  
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   * - 각단계별 예약 함수에 맞게 스크립트 작성
   ***********************************************************************************/
  /** Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선 */
  beforeCreate () {

  },
  /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
  created() {
     let lv_Vm = this

    // 상단탭
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_Close">close</mo-icon></div>',
        methods: {
          fn_Close () {
            lv_Vm.popupClose()
          }
        }
      }
    }

    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.popupClose)
  },
  /** Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선 */
  beforeMount () {

  },
  /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
  mounted() {
    this.$refs['orgNoInput'].focus()
     // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    
  },
  /** Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수 */
  beforeUpdate () {

  },
  /** Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수 */
  updated () {

  },
  /** Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    
  },
  /** Step-8 인스턴스가 Remove 된 후 호출 */
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.popupClose)
  },
  /** Step-Etc1 KeepAlive 화면이 활성화 된 후 호출 - KeepAlive 화면은 떠나도 초기화 되지 않고 유지됨 */
  activated () {

  },
  /** Step-Etc2 KeepAlive 화면이 비활성화 된 후 호출 - KeepAlive 화면은 떠나도 초기화 되지 않고 유지됨 */
  deactivated () {
    
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
   *   지정 함수는 반드시 리턴 하는 함수로 작성
   *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
   ***********************************************************************************/
  computed: {
    //변수가 변경되면 호출되는 Getter
    
  },
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
   ***********************************************************************************/
  watch: {
    //변수가 변경되면 호출되는 함수
    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /*******************************************************************************
     * Function명: popupClose
     * 설명: 팝업닫기
     *******************************************************************************/
    popupClose() {
      this.$emit('onPopupClose')
    },
    /*******************************************************************************
     * Function명: fn_selListOrgNo
     * 설명: 지사검색 
     *******************************************************************************/
    fn_selListOrgNo() {
      //초기화
      this.isError = false
      this.orgInfoList = []
      this.isSearchYn = false
      
      // 필수값 체크
      if(this.vSrchVal.trim() === '') {
        this.$refs['orgNoInput'].focus()
        this.isError = true
        return
      }

      // 조회
      const pParams = { orgInfo: {gssOrgNm: this.vSrchVal} } // userEno: this.lv_UserInfo.userId, fnScCd: '04' } 세션처리시 셋팅값
      const trnstId = 'txTSSBC80S3'
      const auth = 'S'

      this.getStore('progress').dispatch('SKEL_LIST')
      
      this.post(this, pParams, trnstId, auth)
        .then(response => {
          if ( response.body ) {
            console.log(response.body)
            this.orgInfoList = response.body.orgList
            this.isSearchYn = true
            
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        })
    },
    /*******************************************************************************
     * Function명: fn_CheckOrgNo
     * 설명: 지사 체크
     *******************************************************************************/
    fn_CheckOrgNo(item, idx) {
      console.log('선택', idx)
      console.log('선택고객정보', item)
      this.radioValue = String(idx)
      this.orgInfo = {
        gssOrgNm: item.gssOrgNm,
        orgNo: item.orgNo,
      }
      this.isSelectYn = true
    },
    /*******************************************************************************
     * Function명: fn_SelectOrgNo
     * 설명: 지사 선택
     *******************************************************************************/
    fn_SelectOrgNo(item, idx) {
      // 파라미터 들고 이동
      this.$emit('onPopupConfirm', this.orgInfo)
    }

  }
};
</script>